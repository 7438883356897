@import '../elevation';

.px-gdn-table {
  td {
    vertical-align: middle;
    &:nth-child(2) {
      width: 30%;
    }
  }
  thead {
    td {
      border-top: none;
    }
  }
  tbody {
    tr:nth-child(n + 2) {
      td {
        border-top: none;
      }
    }
  }
  .small-pie {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 5px;
  }
}
.table-hover {
  tbody tr {
    @include mat-elevation(0);
    @include mat-elevation-transition();
    &:hover {
      @include mat-elevation(5);
    }
  }
}
.table {
  width: 100%;
}
