@import '../elevation';

$custom-toggle-bg: #e3e3e3;

.px-gdn-toggle > input[type="checkbox"] {
  display: none;
}

.px-gdn-toggle > label {
  position: relative;
  height: 0;
  width: 20px;
  cursor: pointer;
}

.px-gdn-toggle > label::before {
  content: '';
  position:absolute;
  width: 20px;
  height: 8px;
  background: $custom-toggle-bg;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;

}
.px-gdn-toggle > input[type="checkbox"]:checked + label::before {
}

.px-gdn-toggle > label::after {
  content: '';
  position: absolute;
  height: 12px;
  width: 12px;
  left: 0;
  top: -3px;
  background: $custom-toggle-bg;
  border-radius: 6px;
  @include mat-elevation(5);
  transition: all 0.3s ease-in-out;
}
.px-gdn-toggle > input[type="checkbox"]:checked + label::after {
  background: map_get($theme-colors, "primary");
  left: 8px;
}
