/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'font-awesome/scss/font-awesome';
@import 'simple-line-icons/scss/simple-line-icons';
@import 'typography';
@import 'elevation';
@import 'components/dropdown';
@import 'components/card';
@import 'components/buttons';
@import 'components/forms';
@import 'components/icons';
@import 'components/inputs';
@import 'components/tabs';
@import 'components/gallery';
@import 'components/lists';
@import 'components/rating';
@import 'components/toggle';
@import 'components/table';
@import 'components/tooltip';
@import 'components/progress';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'utils/utils';


@include all-utils();

body {
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

button.btn.btn-info:hover {
  color: black;
}

.dt-buttons button.dt-button {
  color: #fff;
  background-color: #084280;
  border-color: #084280;
  box-shadow: none;
  padding: 5px 20px !important;
  margin: 0px 6px 8px 0px;
  border-radius: 50px;
  cursor: pointer;
}

.dataTables_paginate {
  // margin: 30px 0px;
  // float: right;
  display: none !important
}

.dataTables_info {
  // margin: 15px 0px;
  // float: left;
  display: none !important
}

div.dt-buttons {
  position: relative;
  float: left;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  zoom: 1;
}

.dataTables_paginate a.paginate_button {
  color: #084280;
  cursor: pointer;
  padding: 0px 3px;
  margin: 0px 3px
}

.dataTables_paginate a.previous,
.dataTables_paginate a.next {
  color: #fff;
  background-color: #084280;
  border-color: #084280;
  box-shadow: none;
  padding: 5px 20px !important;
  margin: 0px 5px 0px;
  border-radius: 50px;
  cursor: pointer;
}

.dataTables_paginate a.current {
  color: #fff;
  background-color: #084280;
  border-color: #084280;
  box-shadow: none;
  padding: 5px 10px !important;
  margin: 0px 5px 0px;
  border-radius: 50px;
  cursor: pointer;
}

.date {
  height: 45px;
  margin-top: -7px;
}

th {
  text-align: center;
}

.app-login-page img {
  width: 15% !important;
}

.logincard {
  position: absolute;
  left: 0px;
  right: 0x;
  margin: auto !important;
  max-width: 400px !important;
}

.btn-info:hover {
  color: #fff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.table-responsive {
  display: inline-table !important;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.btn-info {
  cursor: pointer !important;
}

//Angular router components
router-outlet~* {
  display: block;
  height: 100%;
  min-height: 100%;
  position: relative;
}

.lighten-3 {
  cursor: pointer;
}

px-gdn-multimenu .menu-scroll ul li a i {
  position: absolute;
  left: 20px !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.54);
}

px-gdn-multimenu .menu-scroll ul li a {
  position: relative;
  height: 20px;
  width: 100%;
  padding-left: 57px !important;
  padding-right: 0px !important;
  line-height: 50px;
  color: inherit;
  text-decoration: inherit;
  transition: all 0.1s ease;
}

.dash-box {
  position: relative;
  // background: linear-gradient(60deg,#00c8fa 0,#0be3df 100%);
  border-radius: 4px;
  text-align: center;
  margin: 0px 0 50px;

}

.dash-box-icon {
  position: absolute;
  left: 43%;
}

.dash-box-action {
  position: absolute;
  left: 33%;
}

.large1 {
  font-size: 50px !important;
  padding-bottom: 0px;
}

.dash-box-body {
  padding: 50px 20px;
  height: 200px;
}

.dash-box-icon:after {
  width: 60px;
  height: 60px;
  position: absolute;
  background: #f5f5f5;
  content: '';
  border-radius: 50%;
  left: -10px;
  top: -10px;
  z-index: -1;
}

.dash-box-icon>i {
  border-radius: 50%;
  line-height: 40px;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 30px;
  padding-top: 19px;
}

.dash-box-icon:before {
  width: 75px;
  height: 75px;
  position: absolute;
  content: '';
  border-radius: 50%;
  left: -17px;
  top: -17px;
  z-index: -2;
}

.input1 {
  background-color: #084280 !important;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 20px;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.d-md-block {
  display: block !important;
  cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  margin-top: 10px;
  margin: 9px 3px 3px 10px;
}

table tr th {
  color: #ffffff;
}

.input1 {
  margin: 0px 0px 0px 2%;
  width: 20%;
  border-radius: 0px !important;
  float: left !important;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
  /* float: right; */
  text-align: right;
  margin-right: 15px;
  margin-top: 10px;
}

.tabl {
  width: 100%;
  float: left;
}

.table {
  background: #fff;
}

.input2 {
  background-color: #0867cc !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 100px !important;
  cursor: pointer;
  margin: 0px 0px 0px 2%;
  width: 20%;
  float: left !important;
  margin-bottom: 20px;
}

.input3 {
  background-color: #084280 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 100px !important;
  cursor: pointer;
  margin: 0px 0px 0px 2%;
  width: 20%;
  float: left !important;
  margin-bottom: 20px;
}

.input1 {
  background-color: #084280 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 100px !important;
  cursor: pointer;
  margin-bottom: 20px;
}

.notifications2 {

  border-radius: 30px;
  box-sizing: border-box;
  text-align: center;
  float: right;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;
  margin-left: 19%;
  margin-top: -22px;
  float: right;
  text-align: right;
}

.notifications2 .num {
  position: absolute;
  top: 11px;
  right: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  color: #222;
  line-height: 25px;
  font-family: sans-serif;
  text-align: center;
  border: 1px #c4c8cc solid;
  font-size: 11px;
}

.notifications1 {
  border-radius: 30px;
  box-sizing: border-box;
  text-align: center;
  float: right;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;
  margin-left: 10%;
  margin-top: -22px;
  float: right;
  text-align: right;
}

.notifications1 .num {
  position: absolute;
  top: 11px;
  right: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  color: #222;
  line-height: 25px;
  font-family: sans-serif;
  text-align: center;
  border: 1px #c4c8cc solid;
  font-size: 11px;
}

.notifications3 {
  border-radius: 30px;
  box-sizing: border-box;
  text-align: center;
  float: right;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;
  margin-left: 31%;
  margin-top: -22px;
  float: right;
  text-align: right;
}

.notifications3 .num {
  position: absolute;
  top: 11px;
  right: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  color: #222;
  line-height: 25px;
  font-family: sans-serif;
  text-align: center;
  border: 1px #c4c8cc solid;
  font-size: 11px;
}


.notifications4 {
  border-radius: 30px;
  box-sizing: border-box;
  text-align: center;
  float: right;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 999;
  margin-left: 44%;
  margin-top: -22px;
  float: right;
  text-align: right;
}

.notifications4 .num {
  position: absolute;
  top: 11px;
  right: 14px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  color: #222;
  line-height: 25px;
  font-family: sans-serif;
  text-align: center;
  border: 1px #c4c8cc solid;
  font-size: 11px;
}


.dash-box-action>button {
  border: none;
  background: #FFF;
  border-radius: 19px;
  padding: 7px 16px;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: .5px;
  color: #003e85;
  box-shadow: 0 3px 5px #d4d4d4;
  cursor: pointer;
}

.dash-box-body>.dash-box-count {
  display: block;
  font-size: 19px;
  color: #FFF;
  font-weight: 300;
}

.dash-box-body>.dash-box-title {
  font-size: 13px;

}

.grey.lighten-3 {
  background-color: #f3f3f3 !important;
  border: 1px #eee solid;
  padding: 20px;
  text-align: center;
  border-radius: 3px;
}

.text-lighten-1 h5 {
  color: #8e8e8e;
  text-transform: uppercase;
}

i.large {
  font-size: 3rem;
  padding-bottom: 15px;
}

.text-lighten-1 h5 {
  font-size: 12px;
}

.indigo-text1.text-lighten-1 {
  color: #8a6d3b !important;
}

.indigo-text2.text-lighten-1 {
  color: #f44336 !important;
}

.indigo-text3.text-lighten-1 {
  color: #a94442 !important;
}

.indigo-text4.text-lighten-1 {
  color: #337ab7 !important;
}

.indigo-text5.text-lighten-1 {
  color: #31708f !important;
}

.indigo-text6.text-lighten-1 {
  color: #26a69a !important;
}

.indigo-text7.text-lighten-1 {
  color: #3c763d !important;
}

.indigo-text8.text-lighten-1 {
  color: #3f51b5 !important;
}

a:hover {
  color: #0be3df;
  text-decoration: none;
}

// .indigo-text8.text-lighten-1 {
//   color: #3f51b5 !important;
// }
.dash-box.dash-box-color-3 {
  background: #eee;

}

.dash-box-color-3 .dash-box-icon:after {
  background: rgba(180, 70, 245, 0.76);
}

.dash-box-color-3 .dash-box-icon:before {
  background: rgba(226, 132, 255, 0.66);
}

.dash-box-color-3 .dash-box-icon>i {
  background: #8150e4;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.125rem;
  padding: 10px;
}

.u-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.form-control {
  border: 1px solid #7abaca !important;
}

.u-input {
  outline: 0;
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  height: 35px;
  font-size: 12px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
}

.c-badge {
  font-size: 10px;
  font-weight: 700;
  min-width: 17px;
  padding: 5px 4px;
  border-radius: 100px;
  display: block;
  line-height: 0.7;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f91605;
}

.c-badge--header-icon {
  position: absolute;
  bottom: -9px;
}

.tooltip {
  width: 120px;
}

.tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: center;
  background-color: #051835;
  font-size: 12px;
  border-radius: 3px;
}

.tooltip-arrow {
  border-right-color: #051835 !important;
}

.hamburger-toggle {
  position: relative;
  padding: 0;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  order: 1;
}

.hamburger-toggle [class*='bar-'] {
  display: block;
  background: #102c58;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .2s ease all;
  transition: .2s ease all;
  border-radius: 2px;
  height: 2px;
  width: 24px;
  margin-bottom: 4px;
}

.hamburger-toggle [class*='bar-']:nth-child(2) {
  width: 18px;
}

.hamburger-toggle [class*='bar-']:last-child {
  margin-bottom: 0;
  width: 12px;
}

.hamburger-toggle.is-opened {
  left: 3px;
}

.hamburger-toggle.is-opened [class*='bar-'] {
  background: #102c58;
}

.hamburger-toggle.is-opened .bar-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 15% 15%;
  transform-origin: 15% 15%;
}

.hamburger-toggle.is-opened .bar-mid {
  opacity: 0;
}

.hamburger-toggle.is-opened .bar-bot {
  -webkit-transform: rotate(45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 15% 95%;
  transform-origin: 15% 95%;
  width: 24px;
}

.hamburger-toggle:focus {
  outline-width: 0;
}

.hamburger-toggle:hover [class*='bar-'] {
  background: #f5642d;
}

.header-icons-group {
  display: flex;
  order: 3;
  height: 100%;
  width: 100%;
  border-left: 1px solid #cccccc;
  background: #eee;
}

.header-icons-group .c-header-icon:last-child {
  border-right: 0;
}

.c-header-icon {
  position: relative;
  display: flex;
  float: left;
  height: 100%;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
  border-right: 1px solid #cccccc;
}

.logo__txt img {
  padding-top: 17px;
}

.c-header-icon i {
  font-size: 18px;
  line-height: 40px;
}

.c-header-icon--in-circle {
  border: 1px solid #d0d0d0;
  border-radius: 100%;
}

.c-header-icon:hover i {
  color: #f5642d;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  font-size: 15px !important;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
  font-size: 15px !important;
}

.l-header {

  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  /* background: linear-gradient(to bottom, #1291ff 0%, #91d1ff 100%); */
  -webkit-transition: padding 0.5s ease-in-out;
  -moz-transition: padding 0.5s ease-in-out;
  -ms-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;
}

.l-header__inner {
  height: 100%;
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: stretch;
  border-bottom: 1px solid;
  border-color: #cccccc;
}

.sidebar-is-expanded .l-header {
  padding-left: 220px;
}

.c-search {
  display: flex;
  height: 100%;
  width: 350px;
}

.c-search__input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0;
  flex-basis: 100%;
  height: 100%;
  border: 0;
  font-size: 14px;
  padding: 0 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.c-dropdown {
  opacity: 0;
  text-align: left;
  position: absolute;
  flex-direction: column;
  display: none;
  width: 300px;
  top: 30px;
  right: -40px;
  background-color: #fff;
  overflow: hidden;
  min-height: 300px;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-box-shadow: 0px 5px 14px -1px #cecece;
  -moz-box-shadow: 0px 5px 14px -1px #cecece;
  box-shadow: 0px 5px 14px -1px #cecece;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.l-sidebar {
  width: 70px;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  background: #327d1a;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -ms-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.l-sidebar .logo {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #051835;
}

.l-sidebar .logo .logo__txt {
  font-size: 26px;
  line-height: 1;
  color: #fff;
  text-align: center;
  font-weight: 700;
}

.l-sidebar__content {
  height: 100%;
  position: relative;
}

.sidebar-is-expanded .l-sidebar {
  width: 220px;
}

.well {
  background: #eee;
}

.px-gdn-btn {
  padding: 9px 33px 10px 30px !important;
  color: #fff;
}

.app-login-page {
  height: 300px !important;
  padding: 1px 16px 70px !important;
}

app-login-page .card {
  max-width: 630px;

}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 6px !important;
  margin-bottom: 10px;
}

app-register-page .card {
  margin-top: 0px !important;
}

.card-bg btn {
  color: #222;
}

.c-menu>ul {
  display: flex;
  flex-direction: column;
}

.c-menu>ul .c-menu__item {
  color: #fff;
  max-width: 100%;
  overflow: hidden;
}

th {
  background-color: #13477e !important;
  color: white;
}

.c-menu>ul .c-menu__item__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 60px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item__inner:before {
  position: absolute;
  content: " ";
  height: 0;
  width: 2px;
  left: 0;
  top: 50%;
  margin-top: -18px;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner {
  border-left-color: #fff;
  background-color: #184c11;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner i {
  color: #fff;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner .c-menu-item__title span {
  color: #fff;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner:before {
  height: 36px;
  opacity: 1;
}

.c-menu>ul .c-menu__item:not(.is-active):hover .c-menu__item__inner {
  background-color: #f5642d;
  border-left-color: #f5642d;
}

.c-menu>ul .c-menu__item i {
  flex: 0 0 70px;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.c-menu>ul .c-menu__item .c-menu-item__expand {
  position: relative;
  left: 100px;
  padding-right: 20px;
  margin-left: auto;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.sidebar-is-expanded .c-menu>ul .c-menu__item .c-menu-item__expand {
  left: 0px;
}

.c-menu>ul .c-menu__item .c-menu-item__title {
  flex-basis: 100%;
  padding-right: 10px;
  position: relative;
  left: 220px;
  opacity: 0;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.c-menu>ul .c-menu__item .c-menu-item__title span {
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sidebar-is-expanded .c-menu>ul .c-menu__item .c-menu-item__title {
  left: 0px;
  opacity: 1;
}

.c-menu>ul .c-menu__item .c-menu__submenu {
  background-color: #051835;
  padding: 15px;
  font-size: 12px;
  display: none;
}

.c-menu>ul .c-menu__item .c-menu__submenu li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid;
  border-color: #072048;
  color: #5f9cfd;
}

.c-menu>ul .c-menu__item .c-menu__submenu li:last-child {
  margin: 0;
  padding: 0;
  border: 0;
}

main.l-main {
  width: 100%;
  height: 100%;
  -webkit-transition: padding 0.5s ease-in-out;
  -moz-transition: padding 0.5s ease-in-out;
  -ms-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;
}

main.l-main .content-wrapper {
  padding: 25px;
  height: 100%;
}

main.l-main .content-wrapper .page-content {
  border-top: 1px solid #d0d0d0;
  padding-top: 25px;
}

main.l-main .content-wrapper--with-bg .page-content {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d0d0d0;
  padding: 25px;
}

main.l-main .page-title {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 25px;
}

.logo__txt {
  height: 70px;
}

a.list-group-item {
  height: auto;
  min-height: 120px;
}

a.list-group-item.active small {
  color: #fff;
}


.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
  border-top: 0;
  border: 1px #eee solid;
  font-size: 13px !important;
}

.side-nav.fixed {
  left: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  position: fixed;
  display: none;
}

.row .col {
  float: left;
  box-sizing: border-box;
  padding: 0px !important;
}

/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin-top: 0px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0px;
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0%;
  border-radius: 10px;

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

.logo img {
  width: 50%;
  // margin: 1px 1px 1px 18%; 
}

/*inputs*/
#msform input,
#msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  font-size: 13px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #26a69a;
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  width: 100px;
  background: #26a69a;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  font-family: 'Exo', sans-serif !important;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #26a69a;
  font-family: 'Exo', sans-serif !important;
}

.logins {
  background: #fff;
  border-radius: 5px;
  margin-top: 1em;
}

#msform .action-button-previous {
  width: 100px;
  background: #C5C5F1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  font-family: 'Exo', sans-serif !important;
}


#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2C3E50;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 0px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  background: #fff;
}

#progressbar li {
  list-style-type: none;
  color: #222;
  text-transform: uppercase;
  font-size: 9px;
  width: 23.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

/* .modal-footer .btn+.btn {
  margin-bottom: 0;
  margin-left: 6px;
  margin: 6px 10px 10px 10px!important;
} */
/* .modal-title {
  margin: 0;
  line-height: 1.42857143;
  color: #fff;
}
.modal2{
  height: 48%!important;
  width: 44%!important;
}
.modal3{s
  height: 21%!important;
  width: 44%!important;
} */
.close {

  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 1 !important;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: #26a69a;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #26a69a;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #26a69a;
  color: white;
}


/* Not relevant to this form */
.dme_link {
  margin-top: 30px;
  text-align: center;
}

.dme_link a {
  background: #FFF;
  font-weight: bold;
  color: #26a69a;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 25px;
  font-size: 12px;
}

.btn-primary {
  margin: 2px;
}

.dme_link a:hover,
.dme_link a:focus {
  background: #C5C5F1;
  text-decoration: none;
}

/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin-top: 0px;
}

aside ul li a span {
  display: table-cell;
  padding-left: 15px;
  text-transform: uppercase;
  font-size: 14px !important;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0px;
  padding: 20px 30px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0%;

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

/*inputs*/
#msform input,
#msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  font-size: 13px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #26a69a;
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  width: 100px;
  background: #26a69a;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #26a69a;
}

#msform .action-button-previous {
  width: 100px;
  background: #C5C5F1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2C3E50;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 0px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  text-transform: uppercase;
  font-size: 9px;
  width: 23.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  text-align: left;

}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: #26a69a;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #26a69a;
  position: absolute;
  left: -47%;
  top: 19px;
  z-index: 1;
  /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #26a69a;
  color: white;
}




/* Not relevant to this form */
.dme_link {
  margin-top: 30px;
  text-align: center;
}

.dme_link a {
  background: #FFF;
  font-weight: bold;
  color: #26a69a;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 25px;
  font-size: 12px;
}

.dme_link a:hover,
.dme_link a:focus {
  background: #C5C5F1;
  text-decoration: none;
}

tr {
  border: 1px #eee solid;
}

table {
  background-color: transparent;
  border: 1px #eee solid !important;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
  border: 1px #eee solid !important;
}

/* .modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
} */
tr {
  border: 1px #eee solid !important;
}

label {
  font-size: 16px;
  color: #222;
}

.grey.lighten-3 {
  background-color: #eee !important;
  border: 1px #ddd solid;
}

/* .modal-dialog {
  width: 600px;
  margin: 0px auto!important;
} */

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
  margin-bottom: 2em !important;
}

.mat-header-cell {
  padding-left: 24px;
  font-size: 15px !important;
  text-transform: capitalize;
  color: #222;
  font-weight: 700;
  text-align: left;
  /* width: 74%; */
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
  font-size: 15px !important;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  font-size: 15px !important;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  font-size: 15px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  line-height: 1.42857143;
  vertical-align: middle !important;
  border: 1px solid #ddd;
  font-size: 12px !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  background: transparent;
}

.btn-warning {
  padding: 10px 25px 10px 25px !important;
}

.l-sidebar {
  background: #337ab7 !important;
}

.c-menu__item.is-active {
  background-color: #3f51b5 !important;
}

.c-menu__item__inner {
  border-bottom: 1px #eee solid;
}

.footer {
  z-index: 9999;
}

thead {
  background: #084280;
  color: #fff;
}

.header {
  width: 100%;
  float: left;
  background: #eee;
  margin-bottom: 2em;
  border: 2px #ddd solid;
  padding: 5px;
  height: 55px;
}

.row .col {
  float: left;
  box-sizing: border-box;
  padding: 0 1.75rem;
}

.service-form {

  width: 100% !important;
  margin: 0 auto;
  margin-bottom: 2em;
}

.c-menu>ul .c-menu__item:not(.is-active):hover .c-menu__item__inner {
  background-color: #1e3e6f !important;
  border-left-color: #1e3e6f !important;
}

.c-menu>ul .c-menu__item.is-active .c-menu__item__inner {
  border-left-color: #5f9cfd;
  background-color: #1e3e6f !important;
}

.service-form {

  width: 100% !important;
  margin: 0 auto;
  margin-bottom: 3em !important;
}

main.l-main {
  width: 100%;
  height: 100%;
  -webkit-transition: padding 0.5s ease-in-out;
  -moz-transition: padding 0.5s ease-in-out;
  -ms-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;

}

.mat-button-wrapper {
  text-decoration: none;
  color: #0077dc;
  text-align: center;
  letter-spacing: .5px;
  transition: .2s ease-out;
  cursor: pointer;
}

.btn-primary {
  color: #0077dc;
}

.btn-danger {
  color: #0077dc;
}

.right-section {
  padding: 15px !important;
  background: #f5f5f5;
  border: 1px #eee solid;
}

/* .modal-dialog {
  width: 100%!important;
  margin: 0px auto;
}
.modal-content{
  background-color: #337ab7!important;
} */
.alert-success {
  opacity: 1;
  font-size: 20px;
}

.header {
  width: 100%;
  float: left;
  background: #ffffff;
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 10px;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
  background: #fff;
}

.navbar {
  border-radius: 0px;


}

.l-sidebar {
  width: 14% !important;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  background: #327d1a;
  transition: width 0.5s ease-in-out;
}

.c-menu-item__title {
  opacity: 1 !important;
  left: 0px !important;
}

.mat-mini-fab {
  margin: 0px 12px 0px 4px !important;
}

.mat-header-cell {
  padding-left: 24px;
  font-size: 21px;
  text-transform: capitalize;
  color: #222;
  font-weight: 700;
}

.col {
  width: 100%;
  float: left;
}

h3.adsc {
  font-size: 15px !important;
  font-weight: 700;
  text-transform: uppercase;
}

.cols {
  width: 100%;
  float: left;
}

::selection {
  color: #fff;
  background: #8956cf;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: #F5F5F5;
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
  display: block;
  height: 6px;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: none;
}

.main-container {
  position: relative;
  display: block;
  width: 100%;
}

#content_body {
  width: 100%;
  display: block;
  margin-left: 136px;
  transition: all .3s cubic-bezier(.55, 0, .1, 1);
}

.margin_left {
  margin-left: 0px !important;
  transition: all .3s cubic-bezier(.55, 0, .1, 1);
}

.notification-btn {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  font-size: 21px;
  margin-top: 10px;
}

.grey.lighten-3:hover {
  background: #1e3e6f
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #fff;
  text-decoration: none;
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.notification-btn .badge {
  color: #010101;
  background-color: #fff;
}

button .badge {
  position: relative;
  top: -40px;
  right: -10px;
}

@media (min-width: 768px) {

  .container>.navbar-header,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container-fluid>.navbar-collapse {
    margin-right: 0;
    margin-left: -10px;
  }

  .cards {
    padding-bottom: 0px !important;
  }
}

/*********notification*********/

.pull-right>.dropdown-menu {
  right: 0;
  left: auto;
  top: 60px;
}

.dropdown-menu {
  position: absolute;
  top: 107%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 280px;
  padding: 0px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: none;
  border: 0px solid;
  border-radius: 0px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown-menu:after {
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  right: 24px;
  position: absolute;
  top: -6px;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  color: #262626;
  text-decoration: none;
  background-color: transparent;
}

.card-title-text {
  padding-left: 15px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 5px 0px;
  margin-bottom: 0px;
  background-color: #fff;
  border: solid 0px;
  border-bottom: 1px solid #ddd;
}

.media-object {
  display: block;
  border-radius: 50%;
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 15px;
}

.notifications_title {
  font-size: 15px;
  color: #4CAF50;
  text-align: right;
  padding: 3px;
  padding-right: 10px;
}

/*******************TOP NAVBAR FIXED NAVBAR END*******************/


/*=======================SIDEBAR NAV START=======================*/
.hit_sidebar {
  position: relative;
  display: block;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: hidden;
  border: none;
  transition: all .3s cubic-bezier(.55, 0, .1, 1);
  border-top: 1px #eee solid;


}

.small_sidebar {
  width: 80px;
  min-width: 64px;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

}

.sidebar-open {
  min-width: 274px;
  width: 274px;
  transform: translate3d(0, 0, 0);
}

.sidebar-slide-push {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  left: -15px;
}

label {
  font-size: 13px !important;
  color: #222;
  text-align: right;
  float: right;
  font-weight: 400;
  padding-top: 4px;
}

.frms {
  padding: 10px;
  margin-top: 0px;
  border: 1px #47aadf solid !important;
  background-color: #fff !important;
}

.form-control {
  border-radius: 0px;
  font-size: 13px !important;
  font-weight: 500;
  width: 100%;
  line-height: 1.42857143;
  border: 1px solid #327d1a;
  background-color: #fff;
  letter-spacing: 0px;
  margin-bottom: 14px;
  --webkit-box-shadow: 0px 11px 10px -10px rgba(0, 0, 0, 0.24);
  font-family: 'Exo', sans-serif !important;
}

/************************SIDEBAR NAV START END************************/
aside ul {
  margin: 0px;
  padding: 0px;
  margin-top: 3em;
}

.admin-chat-logo {
  width: 100%;
  float: left;
  margin: -19px -48% 10px 1%;
}

aside ul li {
  list-style-type: none;
}

aside ul li a {
  font-family: 'Exo', sans-serif !important;
  display: block;
  text-decoration: none;
  text-align: left;
  color: #c8c8c8;
  background-color: transparent;
  font-size: 16px;
  padding: 18px 20px 16px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all .1s ease;
}

aside ul li a:hover {

  color: #fff;
  text-decoration: none;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);

}

.modal-dialog {
  max-width: 500px;
  margin: 8.75rem auto;
}

a:focus {
  outline: none;
  outline-offset: none;
}

aside a:hover,
a:focus {
  color: #fff;
  text-decoration: none;

}

aside ul li a .nav-icon {
  margin-right: 15px;
  font-size: 20px !important;
}

.colsd {
  width: 100%;
  float: left;
  margin-top: -2em;
  margin-bottom: 2em;
}

.tabls {
  background: #eee;
}

.remove_text {
  transition-delay: 2s;
}

.text_hide {
  display: none;
  transition-delay: 2s;
}

aside ul li a span {
  display: table-cell;
  padding-left: 10px;
}

.dropdown,
.dropup {
  position: relative;
  margin-top: 5px !important;
  list-style: none;
  margin-right: 12px;
}

.indigo-text1.text-lighten-1 {
  color: #8a6d3b !important;
}

.indigo-text2.text-lighten-1 {
  color: #f44336 !important;
}

.indigo-text3.text-lighten-1 {
  color: #a94442 !important;
}

.indigo-text4.text-lighten-1 {
  color: #337ab7 !important;
}

.indigo-text5.text-lighten-1 {
  color: #31708f !important;
}

.indigo-text6.text-lighten-1 {
  color: #26a69a !important;
}

.indigo-text7.text-lighten-1 {
  color: #3c763d !important;
}

.indigo-text8.text-lighten-1 {
  color: #3f51b5 !important;
}

.h5,
h5 {
  font-size: 13px !important;
  color: #222 !important;
  font-weight: 300;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(60deg, #00c8fa 0, #0be3df 100%);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.grey {
  margin: 0px 4% 0px 4%;
}

#main {
  transition: margin-left .5s;
  padding: 16px;
  padding-top: 6em;

}

.btn-info {
  color: #fff;
  background-color: #084280;
  border-color: #00c8fa;
  box-shadow: none;
  font-size: 12px !important;
  border-radius: 100px;
  padding: 5px 15px 5px 18px !important;
  border-radius: 100px !important;
}

button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.btn-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important;
  padding: .84rem 2.14rem;
}

.steps-form .steps-row .steps-step .btn-circle {
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px !important;
  margin-top: 0;
}

.btn-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important;
}

.btn-default {
  background-color: #2bbbad;
  color: #fff !important;
}

.btn-rounded {
  -webkit-border-radius: 10em;
  border-radius: 10em;
  padding: .84rem 2.14rem;
}

.cards {
  margin-bottom: 0em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.1rem 1.1rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .125rem;

  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, color .15s ease-in-out, box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 4px 5px 5px 5px !important;
  line-height: 1.428571;
  vertical-align: top;
  border: 1px solid #ddd;
  // font-size: 12px;

}

th.mat-header-cell {
  text-align: left;
  min-width: 100px !important;
}

.mat-sort-header-button {
  border: none;
  background: 0 0;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: inherit;
  outline: 0;
  font: inherit;
  color: currentColor;
}

.modal-dialog {
  max-width: 500px !important;
  margin-right: 23% !important;
}

.form-control:focus {
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0), 0px 0px 7px 0px rgb(19, 71, 126) !important;
}

.cards {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  height: 250px;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: none !important;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 320px) {

  .text-center {
    text-align: center !important;
    font-size: 15px !important;
  }

  .input3 {
    background-color: #084280 !important;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 100px !important;
    cursor: pointer;
    margin: 0px 0px 0px 0% !important;
    width: 20%;
    float: left !important;
    margin-bottom: 20px;
    margin: 0px 0px 0px 0%;
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .input1 {
    background-color: #084280 !important;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 100px !important;
    cursor: pointer;
    margin: 0px 0px 0px 0% !important;
    width: 20%;
    float: left !important;
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .input2 {
    background-color: #084280 !important;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 100px !important;
    cursor: pointer;
    margin: 0px 0px 0px 0% !important;
    width: 20%;
    float: left !important;
    margin-bottom: 20px;
    margin: 0px 0px 0px 0%;
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .login {
    margin-top: 0px !important;
    padding: 10px !important;
  }

  .input1 {
    width: 100% !important;
    margin: 3% 0px 0px 0%;
    margin-bottom: 20px !important;
  }

  .input2 {
    width: 100% !important;
    margin: 3% 0px 0px 0%;
  }

  .pointrck {
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .modal-dialogs {
    padding: 10px !important;
  }

  .wekly {
    margin-top: 9% !important;
    margin-bottom: 3% !important;
  }

  .updateuser,
  label {
    float: left !important;
  }

  .stglit {
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .table-responsive {
    display: block !important;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .qrc {
    margin-top: 4%;
  }

  .cards {
    padding-bottom: 0px !important;
  }

  .admin-chat-logo {
    width: 100%;
    float: left;
    margin: -10px -38% 0px 0%;
  }

  .admin-chat-logo img {
    width: 23%;
    margin-top: -2px;
    margin: 0 auto;
  }

  #main {
    width: 100%;
  }

  .cards {
    margin-bottom: 3em;
  }

  .cols {
    margin-top: 5em;
  }

}

.delete,
.update {
  margin-top: 2em !important;
}

.side-nav {
  background: #004280 !important;
}

.custom-file-label:after {
  background: #084280 !important;
  border: none;
}

.btn-block {
  display: block;
  width: auto !important;
}

.multistep-form-section {
  background: #fff;
}

.frms {
  padding: 10px;
  margin-top: 20px;
}

.input1 {
  background-color: #084280 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-block {
  display: block;
  width: auto !important;
}

.multistep-form-section {
  background: #fff;
}

.frms {
  padding: 10px;
  margin-top: 20px;
}

.input1 {
  background-color: #084280 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px !important;
}

.btn-block {
  display: block;
  width: auto !important;
}

.multistep-form-section {
  background: #fff;
}

.frms {
  padding: 10px;
  margin-top: 20px;
}

.input1 {
  background-color: #084280 !important;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

label {
  font-size: 14px;
  color: #222;
  text-align: right;
  // float: right;
  font-weight: 400;
  padding-top: 4px;

}

.justify-content-md-center {
  justify-content: center !important;
  padding-bottom: 1%;
  padding-top: 4%;
}

.form-group {
  margin-bottom: 1px;
}

.btn-primary {
  color: #fff !important;
  background-color: #13477e !important;
  border-color: #13477e !important;
  box-shadow: none;
}

.mat-button-wrapper {
  text-decoration: none;
  color: #0077dc;
  text-align: center;
  letter-spacing: .5px;
  transition: .2s ease-out;
  cursor: pointer;
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1d4b7c;
  display: block !important;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
  display: block;
}

.m-1,
.icon-note {
  color: #0077dc;
}

.btnupd {
  background-color: #084280 !important;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 20px;
  float: right;

}

.btnupds {
  background-color: #084280 !important;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 20px;
  margin: 0px 9px 0px 0px;
}

.butn {
  margin-bottom: 10px;
}

.loginss {
  margin: 0px 0px;
  color: white;
  padding: 20px;
  height: 620px;
  // background-image: url('../assets/images/DCS\ Maju\ Sponsorship\ logo.jpg');
  // background-size: cover;
}

.btn-info:hover {
  color: #fff;
  background-color: #13477e !important;
  border-color: #13477e !important;
}

.btn-info {
  background-color: #13477e !important;
  border-color: #13477e !important;
  border: none !important;
}

.addpoints {
  cursor: pointer;
  background: #13477e !important;
  color: #fff !important;
}

.cdk-overlay-container {
  display: none !important;
}

.butns {
  background-color: #13477e !important;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 100px !important;
  cursor: pointer;
  margin: 0px 0px 0px 2%;
  width: auto;
  float: left !important;
  margin-bottom: 20px;
}

.butns1 {
  background-color: #0867cc !important;
  color: white;
  padding: 5px 20px;
  border: none;
  border-radius: 100px !important;
  cursor: pointer;
  margin: 0px 0px 0px 2%;
  width: auto;
  float: left !important;
  margin-bottom: 20px;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.toast-top-right {
  animation: slideInRight 0.5s ease-in-out;
}
