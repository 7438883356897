@import 'utils/utils';

//Fonts import
@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url("../assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Raleway;
  src: url("../assets/fonts/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

//Material design icons setup
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('~material-design-icons/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
  url('~material-design-icons/iconfont/MaterialIcons-Regular.woff') format('woff'),
  url('~material-design-icons/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Typography styles
body {
  letter-spacing: 0.03rem;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
